<template>
  <div class="evaluation">
    <div class="doc_info">
      <img :src="docDetail.doctorHeadImg" alt="">
      <div class="info">
        <div class="info_right">
          <p>
            <span>{{docDetail.doctorName}}</span>
            <span>{{docDetail.doctorHospitalName}}</span>
          </p>
          <!-- <p>{{doctorInfo.reply_num}}人咨询</p> -->
        </div>
        <div class="hospital">
          <p>{{docDetail.doctorClinic}}</p>
          <p>{{docDetail.doctorProfessionalTitle}}</p>
        </div>
      </div>
    </div>
    <div class="title">您对本次服务还满意吗</div>
    <div class="expression">
      <div v-for="(item, index) in evaluationCfg" @click="changeAttentionType(item)">
        <img :src="item.attentionType == attentionType ? item.img2 : item.img1"></img>
        <!-- <img src="../assets/img/very_good_selected.png" v-else></img> -->
        <span>{{item.text}}</span>
      </div>
      <!-- <div>
              <img src="../assets/img/good.png" v-if="show"></img>
              <img src="../assets/img/good_selected.png" v-else></img>
              <span>一般</span>
            </div>
            <div>
              <img src="../assets/img/no_good.png" v-if="show"></img>
              <img src="../assets/img/no_good_selected.png" v-else></img>
              <span>不满意</span>
            </div> -->
    </div>
    <div class="text">
      <textarea rows="" v-model="attentionContent" class="cell_group" placeholder="请写下您对医生的评价与建议"></textarea>
    </div>
    <div class="btn">
      <button type="button" name="button" @click="addDoctorEstimateInfo">提交</button>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      show: true,
      userData: JSON.parse(localStorage.getItem('userData')) || '',
      evaluationCfg: [
        { attentionType: 1, text: '满意', img1: require('../assets/img/very_good.png'), img2: require('../assets/img/very_good_selected.png') },
        { attentionType: 2, text: '一般', img1: require('../assets/img/good.png'), img2: require('../assets/img/good_selected.png') },
        { attentionType: 3, text: '不满意', img1: require('../assets/img/no_good.png'), img2: require('../assets/img/no_good_selected.png') },
      ],
      docDetail: {},
      problemId: null,
      attentionType: null,
      interrogationName: null,
      attentionContent: ''
    }
  },
  created() {
    // console.log(this.$route.params)
    this.docDetail = this.$route.params.docDetail;
    this.problemId = this.$route.params.problemId;
    this.interrogationName = this.$route.params.interrogationName;
  },
  methods: {
    changeAttentionType(item) {
      this.attentionType = item.attentionType;
    },
    addDoctorEstimateInfo() {
      let params = {
        memberId: this.userData.memberId,
        doctorId: this.docDetail.doctorId,
        orderNum: this.problemId,
        interrogationPeople: this.interrogationName,
        consultType: 1,
        attentionType: this.attentionType,
        attentionContent: this.attentionContent,
      }
      if (!params.attentionType) {
        Toast('请选择评价等级！');
        return;
      }
      if (params.attentionContent === '') {
        Toast('请填写评价与建议！');
        return;
      }
      this.$http('post', '/server/doctorEstimateInfo/addDoctorEstimateInfo', params, 1).then(res => {
        // console.log(res);
        if (res.data.code == 200) {
          Toast('评价成功！');
          this.$router.replace({ path: '/home' });
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.evaluation {
  .doc_info {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0.2rem;
    width: calc(100% - 0.4rem);
    img {
      width: 1.14rem;
      height: 1.14rem;
      margin-right: 0.2rem;
    }
    .info {
      width: 100%;
      .info_right {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        align-items: center;
        p:nth-child(1) {
          span:nth-child(1) {
            font-size: 0.32rem;
            color: #333;
            margin-right: 0.2rem;
          }
          span:nth-child(2) {
            font-size: 0.28rem;
            color: #777777;
          }
        }
        p:nth-child(2) {
          font-size: 0.28rem;
          color: #afafaf;
        }
      }
      .hospital {
        display: flex;
        align-items: center;
        p:nth-child(1) {
          // width: 1.08rem;
          height: 0.38rem;
          line-height: 0.42rem;
          border: 1px solid #42cec8;
          border-radius: 0.42rem;
          color: #42cec8;
          font-size: 0.24rem;
          text-align: center;
          margin-right: 0.12rem;
          padding: 0 0.2rem;
        }
        p:nth-child(2) {
          color: #555;
          font-size: 0.26rem;
        }
      }
    }
  }
  .title {
    padding: 0.2rem 0.32rem;
    color: #333333;
    font-size: 0.26rem;
  }
  .expression {
    padding: 0.2rem 1rem;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.24rem;
      color: #333333;
      img {
        width: 1.2rem;
        height: 1.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }
  .text {
    padding: 0 0.8rem;
    margin: 0.3rem 0;
    .cell_group {
      border: 1px solid #f2f2f2;
      padding: 0.2rem;
      width: 100%;
    }
  }
  .btn {
    padding: 0.2rem 0.8rem;
    margin: 0.2rem 0;
    width: calc(100% - 1.6rem);
    button {
      width: 100%;
      background: #42cec8;
      height: 0.72rem;
      line-height: 0.72rem;
      color: #ffffff;
      font-size: 0.32rem;
      border-radius: 0.08rem;
      border: 0;
    }
  }
}
</style>
